/**
 * Componente de cambio de contraseña exitoso: Muestra el mensaje de cambio de contraseña exitoso
 */

import { Box, Button, Heading, Stack, CodeIcon, Body } from "codekit";

import { Link } from "react-router-dom";

const Changsuc = () => {
  return (
    <Box textAlign="center">
      <Stack gap={8}>
        <Body variant="body-2">Su contraseña se ha actualizado satisfactoriamente.</Body>
        <Link to={"/login"} state={{ clearShowSuccessMessage: true }}>
          <a href="/login" className="link-full">
            <Button variant="primary-bold" isFull asSpan>
              Siguiente
            </Button>
          </a>
        </Link>
      </Stack>
    </Box>
  );
};

export default Changsuc;
