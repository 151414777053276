import { createSlice } from "@reduxjs/toolkit";

const ventasSlice = createSlice({
  name: "tareas",
  initialState: {
    modalShowTask: false,
  },
  reducers: {
    setModalShowTask: (state, action) => {
      state.modalShowTask = action.payload;
    },
  },
});
export const { setModalShowTask } = ventasSlice.actions;
export default ventasSlice.reducer;

export const getModalShowTask = (state) => {
  return state.tareas.modalShowTask;
};
