/**
 * Funcion que retorna las dos primeras letras en base al nombre y apellido de una persona para el avatar toma en
 * cuenta que el nombre sea NOMBRE APELLIDO o NOMBRES APELLIDOS
 */

const generateAvatarLetters = (userNames) => {

  if (!userNames) {
    return "";
  }

  const namesTrim = userNames.trim();

  const names = namesTrim.split(" ");


  const namesLength = names.length;

  if (namesLength) {
    if (namesLength === 2) {
      const firstName = names[0][0];
      const lastName = names[1][0];
      const initials = `${firstName}${lastName}`;
      return initials;
    }

    if (namesLength === 4) {
      const firstName = names[0][0];
      const lastName = names[2][0];
      const initials = `${firstName}${lastName}`;
      return initials;
    }
  } else {
    return "";
  }
};

export default generateAvatarLetters;
