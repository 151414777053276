/*
 * Componente hijo del dashboard: Renderiza el componente hijo del dashboard
 * Muestra el componente que corresponde al modulo y proceso seleccionado
 */
import { TableLoader } from "codekit";

import { lazy, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { getProcesSelected, getModuleSelected } from "../libs/redux/slices/MenuSlice/menuSlice";
import { resetExport } from "../libs/redux/slices/exportExcSlice/exportExcSlice";
import { resetCustomFilters, resetFilters } from "../libs/redux/slices/trackingSlice/trackingSlice";
import { resetConsult } from "../libs/redux/slices/consultSlice/consultSlice";


const DashboardChildRoute = () => {
  const { modurlxx } = useSelector(getModuleSelected);
  const { produrlx } = useSelector(getProcesSelected);

  const dispatch = useDispatch();

  const canRender = modurlxx && produrlx;
  useEffect(() => {
    //limpio datos de la consulta y exportar
    dispatch(resetExport());
    dispatch(resetConsult());
    dispatch(resetFilters());
    dispatch(resetCustomFilters());
  }, [dispatch, modurlxx, produrlx]);

  if (canRender) {
    let CurrentChildComponent = lazy(() => import(`../components/${modurlxx}/${produrlx}/${produrlx}`));
    return <Suspense fallback={<TableLoader />}>{canRender && <CurrentChildComponent />}</Suspense>;
  }
};

export default DashboardChildRoute;
