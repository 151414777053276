import { shootToast } from "codekit";

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { signOut, getisLoggedIn } from "../../libs/redux/slices/authSlice/authSlice";
import { useLoginMutation } from "../../libs/redux/slices/authSlice/authApiSlice";
import { reset } from "../../libs/redux/slices/MenuSlice/menuSlice";
import { resetCustomFilters } from "../../libs/redux/slices/trackingSlice/trackingSlice";

const Sessionx = () => {
  const [lastActivityTime, setLastActivityTime] = useState(new Date());
  const [isAlertShown, setIsAlertShown] = useState(false);

  const [logout] = useLoginMutation();

  const dispatch = useDispatch();

  const fiftyMinutesAsSeconds = 3000;
  const oneHourAsSeconds = 3600;

  const isLoggedIn = useSelector(getisLoggedIn);

  /**
   * Hook que ejecuta la verificacion de la inactividad cada 5 segundo
   */
  useEffect(() => {
    /**
     * Funcion que cierra la sesion del usuario
     */
    const onLogout = async () => {
      await logout();
      dispatch(signOut());
      dispatch(reset());
      dispatch(resetCustomFilters());
    };

    /**
     * Funcion que compara la fecha actual con la ultima actividad para ver si hubo inactividad
     */
    const checkInactivity = () => {
      const currentTime = new Date();
      const inactiveTimeAsSeconds = (currentTime - lastActivityTime) / 1000;
      if (inactiveTimeAsSeconds > fiftyMinutesAsSeconds && inactiveTimeAsSeconds <= oneHourAsSeconds && !isAlertShown) {
        setIsAlertShown(true);
        shootToast({
          text: "Tu sesión está a punto de expirar debido a la inactividad. Por favor, realiza alguna acción para mantenerla activa.",
          variant: "info",
          autoHide: true,
          duration: 10000,
        });
      } else if (inactiveTimeAsSeconds >= oneHourAsSeconds) {
        onLogout();
      }
    };

    if (isLoggedIn) {
      const interval = setInterval(checkInactivity, 5000);
      return () => clearInterval(interval);
    }
  }, [lastActivityTime, isLoggedIn, dispatch, isAlertShown, logout]);

  /**
   * Hook que registra y remueve los eventos que generan actividad por parte del usuario
   */
  useEffect(() => {
    /**
     * Funciona que guarda una nueva fecha de la ultima vez que hubo actividad
     */
    const handleActivity = () => {
      if (isLoggedIn) {
        setLastActivityTime(new Date());
      }
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("click", handleActivity);
    window.addEventListener("keydown", handleActivity);

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("click", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, [isLoggedIn]);

  return <></>;
};

export default Sessionx;
