import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../slices/apiSlice";
import authSlice from "../slices/authSlice/authSlice";
import forgotSlice from "../slices/forgotSlice/forgotSlice";
import passwordSlice from "../slices/passwordSlice/passwordSlice";
import menuSlice from "../slices/MenuSlice/menuSlice";
import trackingSlice from "../slices/trackingSlice/trackingSlice";
import { dataTrackingSlice } from "../slices/trackingSlice/trackingApiSlice";
import { menuApliSlice } from "../slices/MenuSlice/menuApliSlice";
import consultSlice from "../slices/consultSlice/consultSlice";
import exportExcSlice from "../slices/exportExcSlice/exportExcSlice";
import usuariosSlice from "../slices/Paramgen/Usuarios/usuariosSlice";
import errorsSlice from "../slices/Errorsxx/errorsSlice";
import confirmSlice from "../slices/Confirmx/confirmSlice";
import ventasSlice from "../slices/Ventasxx/ventasSlice";
import auditoriSlice from "../slices/Auditory/auditoriSlice";
//Redux persist
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import { genioxxxApiSlice } from "../slices/Genioxxx/genioxxxApiSlice";

import thunk from "redux-thunk";

const persistConfig = {
  key: "auth",
  storage: storageSession,
};
const persistedReducer = persistReducer(persistConfig, authSlice);

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [genioxxxApiSlice.reducerPath]: genioxxxApiSlice.reducer,
    persistedReducer,
    forgot: forgotSlice,
    aundit: auditoriSlice,
    password: passwordSlice,
    menu: menuSlice,
    tracking: trackingSlice,
    consult: consultSlice,
    exportexcl: exportExcSlice,
    usuarios: usuariosSlice,
    errors: errorsSlice,
    confirm: confirmSlice,
    tareas: ventasSlice,
  },
  devTools: process.env.REACT_APP_CASTOR_DEBUG,
  middleware: [thunk, dataTrackingSlice.middleware, menuApliSlice.middleware],
});

export const persistor = persistStore(store);
