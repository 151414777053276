import { shootToast, Box, Flexbox, Heading, PieChartsTable, CodeIcon, Button } from "codekit";
import { useEffect, useState, useRef } from "react";

import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useLinkDocuMutation, useCargDocsMutation } from "../libs/redux/slices/Ganxxxxx/ganApiSlice";

const LinkDoc = () => {
  const [dataSubDoc] = useLinkDocuMutation();
  const [cargDocs] = useCargDocsMutation();

  const [DataGraficas, setDataGraficas] = useState([]);
  const [DataTercero, setDataTercero] = useState([]);
  const [DataListaDoc, setDataListaDoc] = useState([]);
  const [Total, setTotal] = useState([]);

  const dataColorsCode = (fieldNames) => {
    return fieldNames.map((fieldName) => {
      switch (fieldName) {
        case "APROBADO":
          return {
            colorCellChart: "success-500",
            bgColorCards: "success-100",
            iconColor: "success-400",
            textColor: "success-600",
          };
        case "PENDIENTE":
          return {
            colorCellChart: "warning-400",
            bgColorCards: "warning-400",
            iconColor: "warning-400",
            textColor: "warning-400",
          };
        case "RECHAZADO":
          return {
            colorCellChart: "error-500",
            bgColorCards: "error-100",
            iconColor: "error-400",
            textColor: "error-500",
          };
      }
    });
  };

  const dataColumnsDocs = ["ESTADO", "TOTAL", "COLOR"];

  const fetchData = async () => {
    try {
      const response = await dataSubDoc(params.token);
      setDataGraficas(response.data.aDataGraf.docsterc);
      setTotal(response.data.aDataGraf.total);
      setDataTercero(response.data.oTercero);
      setDataListaDoc(response.data.aListDocs);
    } catch (error) {
      shootToast({
        variant: "error",
        text: "las Graficas no se cargaron correctamente",
        duration: 6000,
        autoHide: true,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const params = useParams();

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileUpload = (event, documentId) => {
    const file = event.target.files[0];
    if (file) {
      handleUpload(file, documentId);
    }
  };

  const handleUpload = async (file, documentId) => {
    try {
      const formData = new FormData();
      formData.append("filexxxx", file);
      formData.append("iddocxxx", documentId);
      formData.append("teridxxx", DataTercero.teridxxx);

      await cargDocs(formData);
      setSelectedFiles([]);
      fetchData(); // Refresh data after upload
    } catch (error) {
      shootToast({
        variant: "error",
        text: "La informacion no fue cargada correctamente",
        duration: 6000,
        autoHide: true,
      });
    }
  };

  const indicexxValues = [...new Set(DataGraficas.map((item) => item.indicexx))];

  const colors = dataColorsCode(indicexxValues);

  const pieChartData = DataGraficas.map((item, index) => ({
    name: item.indicexx || "Vacio",
    value: item.cantidad,
    value1: `${item.cantidad} (${item.percentx})`,
    color: item.graphcol,
    key: index,
  }));

  return (
    <>
      <Box bg="Neutros-50">
        <div className="container mx-auto px-10">
          <Box spacing={4} borderBottom={1} bg="white" mb={6} borderColor="neutral-300">
            <Flexbox justifyContent="center" gap={4} alignItems="center">
              <Heading variant="heading-3">{DataTercero.ternomxx} - # {DataTercero.teridxxx}</Heading>
            </Flexbox>
          </Box>

          <Box spacing={4} borderBottom={1} bg="white" borderColor="neutral-300">
            <Flexbox justifyContent="start" gap={4} alignItems="center">
              <Heading color="secondary-600" variant="heading-6">
                Estados de Documento
              </Heading>
            </Flexbox>
          </Box>

          <Box mt={6} spacing={6} bg="white">
            <Heading variant="heading-6">Avance general</Heading>
            {DataGraficas && DataGraficas.length > 0 ? (
              <PieChartsTable
                data={pieChartData}
                chartColors={colors}
                labelOne={Total}
                labelTwo="Total"
                percentageMessage={`${Total} Total`}
                columns={dataColumnsDocs}
              />
            ) : (
              <p>No hay datos disponibles</p>
            )}
          </Box>

          <Box spacing={4} mt={6} bg="white">
            <Box mb={6}>
              <Flexbox justifyContent="start" gap={4} alignItems="center">
                <Heading color="secondary-600" variant="heading-6">
                  Lista de documentos solicitados
                </Heading>
              </Flexbox>
            </Box>
            <Box mb={6}>
              <div className="overflow-x-auto">
                <table className="table-auto w-full border-collapse border-t border-gray-300">
                  <thead>
                    <tr className="bg-neutral-300">
                      <th className="px-4 py-2">NOMBRE DEL DOCUMENTO</th>
                      <th className="px-4 py-2">ESTADO</th>
                      <th className="px-4 py-2">OBSERVACIONES</th>
                      <th className="px-4 py-2">ACCIONES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {DataListaDoc.map((item) => (
                      <tr key={item.iddocxxx}>
                        <td className="px-4 py-3 border-b border-gray-300">{item.nomdocxx}</td>
                        <td className="px-4 py-3 border-b border-gray-300">{item.estadoxx}</td>
                        <td className="px-4 py-3 border-b border-gray-300">{item.observac || "-"}</td>
                        <td className="px-4 py-3 border-b border-gray-300 flex items-center">
                          {item.pathfile && item.estadoxx === "APROBADO" ? (
                            // Si el pathfile existe y el estado es APROBADO, mostrar solo el botón de ver
                            <a
                              href={item.pathfile}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="inline-block px-4 py-3 bg-Neutros-50 rounded-md text-gray-700 hover:bg-gray-400 hover:text-gray-800 transition duration-300 flex items-center"
                            >
                              <Box className="ml-2" spacingX={2}>
                                <CodeIcon variant={"eye"} />
                              </Box>
                            </a>
                          ) : item.pathfile && (item.estadoxx === "RECHAZADO" || !item.estadoxx) ? (
                            // Si el pathfile existe y el estado es RECHAZADO o está vacío, mostrar el enlace de ver y el botón de adjuntar
                            <>
                              <div className="flex space-x-4">
                                <a
                                  href={item.pathfile}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="inline-block px-4 py-3 bg-Neutros-50 rounded-md text-gray-700 hover:bg-gray-400 hover:text-gray-800 transition duration-300 flex items-center"
                                >
                                  <Box className="ml-2" spacingX={2}>
                                    <CodeIcon variant={"eye"} />
                                  </Box>
                                </a>
                                <label
                                  htmlFor={`fileInput${item.iddocxxx}`}
                                  className={`inline-block px-4 py-3 rounded-md text-gray-700 hover:bg-gray-400 hover:text-gray-800 cursor-pointer flex items-center ${
                                    selectedFiles.some((file) => file.key === item.iddocxxx)
                                      ? "bg-success-400"
                                      : "bg-Neutros-50"
                                  }`}
                                >
                                  <Box className="ml-2" spacingX={2}>
                                    <CodeIcon variant={"files-plus"} />
                                  </Box>
                                  <input
                                    id={`fileInput${item.iddocxxx}`}
                                    onChange={(event) => handleFileUpload(event, item.iddocxxx)}
                                    type="file"
                                    className="hidden"
                                  />
                                </label>
                              </div>
                            </>
                          ) : item.pathfile ? (
                            // Si el pathfile existe y el estado no es APROBADO, RECHAZADO ni está vacío, mostrar solo el botón de adjuntar
                            <label
                              htmlFor={`fileInput${item.iddocxxx}`}
                              className={`inline-block px-4 py-3 rounded-md text-gray-700 hover:bg-gray-400 hover:text-gray-800 cursor-pointer flex items-center ${
                                selectedFiles.some((file) => file.key === item.iddocxxx)
                                  ? "bg-success-400"
                                  : "bg-Neutros-50"
                              }`}
                            >
                              <Box className="ml-2" spacingX={2}>
                                <CodeIcon variant={"files-plus"} />
                              </Box>
                              <input
                                id={`fileInput${item.iddocxxx}`}
                                onChange={(event) => handleFileUpload(event, item.iddocxxx)}
                                type="file"
                                accept=".csv,.doc,.docx,.pdf,.jpg,.jpeg,.png"
                                className="hidden"
                              />
                            </label>
                          ) : (
                            // Si no hay pathfile, mostrar solo el botón de adjuntar
                            <label
                              htmlFor={`fileInput${item.iddocxxx}`}
                              className={`inline-block px-4 py-3 rounded-md text-gray-700 hover:bg-gray-400 hover:text-gray-800 cursor-pointer flex items-center ${
                                selectedFiles.some((file) => file.key === item.iddocxxx)
                                  ? "bg-success-400"
                                  : "bg-Neutros-50"
                              }`}
                            >
                              <Box className="ml-2" spacingX={2}>
                                <CodeIcon variant={"files-plus"} />
                              </Box>
                              <input
                                id={`fileInput${item.iddocxxx}`}
                                onChange={(event) => handleFileUpload(event, item.iddocxxx)}
                                type="file"
                                className="hidden"
                              />
                            </label>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Box>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default LinkDoc;
