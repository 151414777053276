import { createSlice } from "@reduxjs/toolkit";

const usuariosSlice = createSlice({
  name: "usuarios",
  initialState: {
    dataEdit: {},
  },
  reducers: {
    setDataEdit: (state, action) => {
      state.dataEdit = action.payload;
    },
  },
});

export const { setDataEdit } = usuariosSlice.actions;
export default usuariosSlice.reducer;
//Export Data
export const getDataEdit = (state) => {
  return state.usuarios.dataEdit;
};
