import { apiSlice } from "../../apiSlice";

export const ventasxxApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    CreateVentas: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Ventasxx`,
        method: "POST",
        body: parameters,
      }),
    }),
    UpdateVentas: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Ventasxx/${parameters.vtcaidxx}`,
        method: "PUT",
        body: parameters,
      }),
    }),
    SearchCotizaId: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Ventasxx/${parameters.idCotizacion}`,
        method: "GET",
      }),
    }),
    CotiDocs: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Ventasxx/CotiDocs`,
        method: "POST",
        body: parameters,
      }),
    }),
    Qtacepta: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Ventasxx/Qtacepta`,
        method: "POST",
        body: parameters,
      }),
    }),
    SearchTbCargos: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Ventasxx/TbCargos/${parameters.idCotizacion}`,
        method: "GET",
      }),
    }),
    CalculoVacobven: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Ventasxx/Vacobven`,
        method: "POST",
        body: parameters,
      }),
    }),
    CalculoIvacalcx: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Ventasxx/Ivacalcx`,
        method: "POST",
        body: parameters,
      }),
    }),
    SaveChargesx: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Chargevt`,
        method: "POST",
        body: parameters,
      }),
    }),
    getItemsCargos: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Chargevt`,
        method: "GET",
        params: parameters,
      }),
    }),
    InactiveChargesx: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Chargevt/${parameters.chidxxxx}`,
        method: "DELETE",
        body: parameters,
      }),
    }),
    SaveTotalcon: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/ConTotal`,
        method: "POST",
        body: parameters,
      }),
    }),
    UpdateTotalcon: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/ConTotal/${parameters.cotnumxx}`,
        method: "PUT",
        body: parameters,
      }),
    }),
    getItemsConTotal: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/ConTotal`,
        method: "GET",
        params: parameters,
      }),
    }),
    GeneratePdf: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Ventasxx/Qtgenpdf`,
        method: "POST",
        body: parameters,
      }),
    }),
    SaveDataEmail: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Ventasxx/Qtsendma`,
        method: "POST",
        body: parameters,
      }),
    }),
    SaveDataQtnotesx: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Ventasxx/Qtnotesx`,
        method: "POST",
        body: parameters,
      }),
    }),
    SearchVaricove: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Ventasxx/Varicove/${parameters.idCotizacion}`,
        method: "GET",
      }),
    }),
    SaveDesist: builder.mutation({
      query: (parameters) => ({
        url: `/Ventasxx/Ventasxx/Qtclause`,
        method: "POST",
        body: 
          parameters,
        
      }),
    }),
  }),
});

export const {
  useCreateVentasMutation,
  useUpdateVentasMutation,
  useSearchCotizaIdMutation,
  useCotiDocsMutation,
  useQtaceptaMutation,
  useSearchTbCargosMutation,
  useCalculoVacobvenMutation,
  useCalculoIvacalcxMutation,
  useSaveChargesxMutation,
  useGetItemsCargosMutation,
  useSaveTotalconMutation,
  useUpdateTotalconMutation,
  useGetItemsConTotalMutation,
  useInactiveChargesxMutation,
  useGeneratePdfMutation,
  useSaveDataEmailMutation,
  useSaveDataQtnotesxMutation,
  useSearchVaricoveMutation,
  useSaveDesistMutation,
} = ventasxxApiSlice;
