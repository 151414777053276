/*
 * Pagina de inicio de sesion: Permite iniciar sesion en la plataforma
 * Tiene 4 estados posibles: Formulario de inicio de sesion, Confirmacion de codigo de seguridad,
 * Formulario de cambio de contraseña y pantalla de cambio exitoso
 */

import Changpwd from "../components/Changpwd/Changpwd";
import Changsuc from "../components/Changsuc/Changsuc";
import Codeconf from "../components/Codeconf/Codeconf";
import Lognform from "../components/Lognform/Lognform";
import Updpwdxx from "../components/Updpwdxx/Updpwdxx";
import LoginPageChangePasswordWrapper from "./LoginPageChangePasswordWrapper";

import { useSelector } from "react-redux";

import {
  getisChangePass,
  getisLoggedUserPassword,
  getShowSuccessMessage,
} from "../libs/redux/slices/authSlice/authSlice";

const LoginPage = () => {
  const isLoggedUserPass = useSelector(getisLoggedUserPassword);
  const isChangePass = useSelector(getisChangePass);
  const isShowSuccessMessage = useSelector(getShowSuccessMessage);

  let CurrentChildComponent = null;

  if (isChangePass) {
    CurrentChildComponent = (
      <LoginPageChangePasswordWrapper showPasswordRules={true}>
        <Updpwdxx origin="LOGIN-PAGE" />
      </LoginPageChangePasswordWrapper>
    );
  } else if (isShowSuccessMessage) {
    CurrentChildComponent = (
      <LoginPageChangePasswordWrapper isSuccessPasswordRecovery={isShowSuccessMessage}>
        <Changsuc />
      </LoginPageChangePasswordWrapper>
    );
  } else if (isLoggedUserPass) {
    CurrentChildComponent = (
      <LoginPageChangePasswordWrapper showLoginTicketCard={true}>
        <Codeconf origin="LOGIN-PAGE" />
      </LoginPageChangePasswordWrapper>
    );
  } else {
    CurrentChildComponent = <Lognform />;
  }

  return <>{CurrentChildComponent}</>;
};

export default LoginPage;
