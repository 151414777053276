/*
 * Pagina del Dashboard: Permite navegar dentro de la aplicacion
 * Muestra la barra de navegacion, el acceso al perfil y renderiza el modulo y proceso seleccionado
 */

import {
  Dashboard,
  DashboardMain,
  Description,
  MenuWrapper,
  MenuItems,
  HeaderBoxLeft,
  HeaderBoxRight,
  HeaderNotifications,
  HeaderNotificationItem,
  HeaderWrapper,
  HeaderSearch,
  HeaderUser,
  HeaderUserItem,
  StackRow,
  Flexbox,
  CodeIcon,
} from "codekit";
import Homenews from "../components/Homenews/Homenews";

import DashboardChildRoute from "./DashboardChildRoute";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getDataUser, getMenuUser, signOut, setMenuUser } from "../libs/redux/slices/authSlice/authSlice";
import {
  getModuleSelected,
  reset,
  setModuleSelected,
  setProcesSelected,
} from "../libs/redux/slices/MenuSlice/menuSlice";
import { useLoginMutation } from "../libs/redux/slices/authSlice/authApiSlice";
import { useOrderMenuMutation } from "../libs/redux/slices/MenuSlice/menuApliSlice";

import capitalizeWord from "../utils/capitalize-words";
import generateAvatarLetters from "../utils/avatar-letters-generator";

// tareas
import { setModalShowTask } from "../libs/redux/slices/Ventasxx/ventasSlice";

const DashboardPage = () => {
  const dispatch = useDispatch();

  const userData = useSelector(getDataUser);
  const menuData = useSelector(getMenuUser);

  const { modurlxx } = useSelector(getModuleSelected);
  const { usrnomco, usremail, carnomxx, usridxxx } = userData;

  const [orderMenu] = useOrderMenuMutation();
  const [logout] = useLoginMutation();

  const [menuDataMap, setMenuDataMap] = useState([]);

  const [showNews, setShowNews] = useState(true);

  const [ModuleSelecTaks, setModuleSelecTaks] = useState("");

  /**
   * Hook que establece los elementos del menu de navegacion y actualiza su estado activo cuando se seleccionan
   */
  useEffect(() => {
    /**
     * Funcion para guardar el modulo y proceso elegido del menu de navgacion
     */
    const onSelectOption = (moduleItem, processItem) => {
      setShowNews(false);
      setModuleSelecTaks(moduleItem);
      dispatch(setModuleSelected(moduleItem));
      dispatch(setProcesSelected(processItem));
    };

    /**
     * Funcion que genera los items del menu de navegacion
     */
    const menuDataMapper = () => {
      return menuData.map((menu) => {
        let isActive = false;

        if (modurlxx) {
          isActive = menu.modurlxx === modurlxx ? true : false;
        }

        const menuDataItem = {
          id: menu.modidxxx,
          description: menu.modnomxx,
          url: menu.modurlxx,
          icon: `${menu.modicoxx}`,
          isActive: isActive,
          items: menu.sys00004.map((subItem) => ({
            id: subItem.proidxxx,
            description: subItem.pronomxx,
            url: subItem.Prodtype,
            onClick: () => {
              onSelectOption(menu, subItem);
            },
          })),
        };
        return menuDataItem;
      });
    };

    const menuDataMap = menuDataMapper();

    setMenuDataMap(menuDataMap);
  }, [modurlxx, menuData, dispatch]);

  /**
   * Funcion para cerrar sesion del usuario
   */
  const onLogout = async () => {
    await logout();
    dispatch(signOut());
    dispatch(reset());
  };

  /**
   * Funcion que vacia el modulo y proceso elegido
   */
  const handleClickHome = () => {
    setShowNews(true);
    setModuleSelecTaks("");
    dispatch(setModuleSelected(""));
    dispatch(setProcesSelected(""));
  };

  /**
   * Funcion para reordenar los elementos del menu
   */
  const reorderMenuData = (context) => {
    const indexes = context.item.indexes;
    const newIndex = indexes.new;
    const previousIndex = indexes.previous;

    const newMenuData = [...menuData];
    const [removed] = newMenuData.splice(previousIndex, 1);
    newMenuData.splice(newIndex, 0, removed);
    return newMenuData;
  };

  /**
   * Funcion que sirve para guardar el nuevo orden del menu
   */
  const setNewMenuOrder = async (newDataMenu) => {
    const data = newDataMenu.map((module) => {
      const MODIDXXX = module.MODIDXXX;

      return {
        MODIDXXX,
        usridxxx,
      };
    });
    await orderMenu(data).unwrap();
  };

  /**
   * Funcion que maneja el arrastrado de los items del menu
   */
  const handleMenuDragging = (context) => {
    // Set new menuDataMap order
    const items = context._newItems;
    setMenuDataMap(items);

    // Set new menuData order
    const newMenuData = reorderMenuData(context);
    dispatch(setMenuUser(newMenuData));
    setNewMenuOrder(newMenuData);
  };

  const Modtask = () => {
    if (ModuleSelecTaks.modidxxx === 101) {
      dispatch(setModalShowTask(true));
    }
  };

  return (
    <Dashboard>
      <HeaderWrapper>
        <HeaderBoxLeft>
          <HeaderSearch />
        </HeaderBoxLeft>
        <HeaderBoxRight>
          <HeaderBoxRight>
            <StackRow forceWidth>
              <Flexbox justifyContent="end" alignItems="end" gap={4}>
                <button onClick={Modtask}>
                  <CodeIcon variant="task" color="white" width={25} />
                </button>
              </Flexbox>
            </StackRow>
            <HeaderNotifications quantity={12}>
              <HeaderNotificationItem image="https://ui-avatars.com/api/?name=Yessica+Dominguez">
                <Description variant="description-2">
                  <strong>Yessica Dominguez</strong> ha modificado el campo <strong>Documento 1526</strong>
                </Description>
                <Description variant="description-3">
                  <span className="text-neutral-400">24 de Ago, 2023</span>
                </Description>
              </HeaderNotificationItem>
              <HeaderNotificationItem image="https://ui-avatars.com/api/?name=Yessica+Dominguez">
                <Description variant="description-2">
                  <strong>Yessica Dominguez</strong> ha modificado el campo <strong>Documento 1526</strong>
                </Description>
                <Description variant="description-3">
                  <span className="text-neutral-400">24 de Ago, 2023</span>
                </Description>
              </HeaderNotificationItem>
              <HeaderNotificationItem image="https://ui-avatars.com/api/?name=Yessica+Dominguez">
                <Description variant="description-2">
                  <strong>Yessica Dominguez</strong> ha modificado el campo <strong>Documento 1526</strong>
                </Description>
                <Description variant="description-3">
                  <span className="text-neutral-400">24 de Ago, 2023</span>
                </Description>
              </HeaderNotificationItem>
            </HeaderNotifications>
          </HeaderBoxRight>
          <HeaderUser
            image={generateAvatarLetters(usrnomco)}
            name={capitalizeWord(usrnomco)}
            cargo={carnomxx}
            email={usremail.toLowerCase()}
          >
            <HeaderUserItem icon="logout" onClick={onLogout}>
              Cerrar sesión
            </HeaderUserItem>
          </HeaderUser>
        </HeaderBoxRight>
      </HeaderWrapper>
      <MenuWrapper>
        <HeaderUser
          image={generateAvatarLetters(usrnomco)}
          name={capitalizeWord(usrnomco)}
          cargo={carnomxx}
          email={usremail.toLowerCase()}
          isInsideMenu
        >
          <HeaderUserItem icon="logout" onClick={onLogout}>
            Cerrar sesión
          </HeaderUserItem>
        </HeaderUser>
        <MenuItems
          labelHome="Inicio"
          onClickHome={handleClickHome}
          onMenuDragged={handleMenuDragging}
          items={menuDataMap}
        />
      </MenuWrapper>
      <DashboardMain>
        <DashboardChildRoute />
        {showNews && <Homenews />}
      </DashboardMain>
    </Dashboard>
  );
};

export default DashboardPage;
