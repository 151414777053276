import "codekit/style";

import { Toast } from "codekit";
import LoginPage from "./pages/LoginPage";
import RecoverPasswordPage from "./pages/RecoverPasswordPage";
import LinkDoc from "./pages/linkDoc";
import LinkAcceptReject from "./pages/LinkAcceptReject";
import DashboardPage from "./pages/DashboardPage";
import Confirmx from "./components/Confirmx";
import Sessionx from "./components/Sessionx";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Routes, Route, useLocation } from "react-router-dom";

import {
  signOut,
  getisLoggedIn,
  getisLoggedUserPassword,
  showSuccessMessage as showSuccessMessageForgot,
} from "./libs/redux/slices/authSlice/authSlice";
import { reset, showSuccessMessage as showSuccessMessageAuth } from "./libs/redux/slices/forgotSlice/forgotSlice";
import { reset as resetPasswordStrength } from "./libs/redux/slices/passwordSlice/passwordSlice";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const isLoggedIn = useSelector(getisLoggedIn);
  const isLoggedUserPass = useSelector(getisLoggedUserPassword);

  /**
   * Hook que detecta los cambios de ruta del Router y borra estados temporales
   */
  useEffect(() => {
    /**
     * Funcion para borrar el estado que muestra el mensaje de cambio de contraseña correcta
     */
    const clearShowSuccessMessage = () => {
      const state = location.state;
      if (state) {
        const hasClearShowSuccessMessage = state.hasOwnProperty("clearShowSuccessMessage");
        if (hasClearShowSuccessMessage) {
          dispatch(showSuccessMessageAuth(false));
          dispatch(showSuccessMessageForgot(false));
        }
      }
    };

    /**
     * Funcion para borrar el estado que muestra la confirmacion de cambio de contraseña
     */
    const clearCodeConfirmation = () => {
      const state = location.state;
      if (state) {
        const hasclearCodeConfirmation = state.hasOwnProperty("clearCodeConfirmation");
        if (hasclearCodeConfirmation) {
          dispatch(reset());
          dispatch(signOut());
        }
      }
    };

    /**
     * Funcion para borrar el estado que muestra la fuerza de la contraseña en la pagina de cambio de contraseña
     */
    const clearPasswordStrength = () => {
      dispatch(resetPasswordStrength());
    };

    clearShowSuccessMessage();
    clearCodeConfirmation();
    clearPasswordStrength();
  }, [dispatch, location]);

  /**
   * Hook que valida si la persona recarga o cierra la ventana y no completo el login pueda volver a iniciar
   */
  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      if (!isLoggedIn && isLoggedUserPass) {
        dispatch(signOut());
      } else if (!isLoggedIn && !isLoggedUserPass) {
        dispatch(signOut());
      }
    };
    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, [dispatch, isLoggedIn, isLoggedUserPass]);

  return (
    <>
      <Routes>
        <Route exact path="/" element={isLoggedIn ? <Navigate to="/home" /> : <Navigate to="/login" />} />
        <Route path="login" element={!isLoggedIn ? <LoginPage /> : <Navigate to="/" />} />
        <Route path="recover-password" element={<RecoverPasswordPage />}></Route>
        <Route path="LinkDoc/:token" element={<LinkDoc />}></Route>
        <Route path="LinkAcceptReject/:token/:status/:language" element={<LinkAcceptReject />}></Route>
        <Route path="home" element={isLoggedIn ? <DashboardPage /> : <Navigate to="/" />}></Route>
      </Routes>
      <Toast />
      <Confirmx />
      {isLoggedIn && <Sessionx />}
    </>
  );
}

export default App;
