import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: "",
    token: null,
    infoUser: null,
    menuUser: null,
    isLoggedUserPass: false,
    isLoggedIn: false,
    isChangePass: false,
    initialMenuUser: null,
    isReplaceUser: false,
    userReplace: "",
    showUserReplace: false,
    showSuccessMessage: false,
    passwordFormMessage: "",
    tokenGenio: null,
  },
  reducers: {
    signIn: (state, action) => {
      const { usridxxx } = action.payload.userData.data;
      state.user = usridxxx.toString();
      state.isLoggedUserPass = true;
    },
    signToken: (state, action) => {
      const { token } = action.payload.userData.data[0];
      const { infouser } = action.payload.userData.data[0];
      const { usridxxx } = action.payload.userData.data[0].infouser;
      state.user = usridxxx.toString();
      state.infoUser = infouser;  
      state.menuUser = infouser.usrpermx;
      state.token = token;
      state.isLoggedIn = true;
      state.initialMenuUser = infouser.usrpermx;
      state.showUserReplace = infouser.REPLACEX;
    },
    signOut: (state) => {
      state.user = null;
      state.token = null;
      state.infoUser = null;
      state.menuUser = null;
      state.isLoggedUserPass = false;
      state.isLoggedIn = false;
      state.isChangePass = false;
      state.initialMenuUser = null;
      state.passwordFormMessage = "";
      state.tokenGenio = null;
    },
    changePass: (state, action) => {
      const { usridxxx, message } = action.payload.error.data.errors;
      state.user = usridxxx.toString();
      state.isChangePass = true;
      state.passwordFormMessage = message;
    },
    setMenuUser: (state, action) => {
      state.menuUser = action.payload;
    },
    setMenuUserReplace: (state, action) => {
      state.menuUser = action.payload;
      state.infoUser = { ...state.infoUser, usrpermx: action.payload };
    },
    setMenuInitialUser: (state) => {
      state.menuUser = state.initialMenuUser;
      state.infoUser = { ...state.infoUser, usrpermx: state.initialMenuUser };
    },
    setIsReplaceUser: (state, action) => {
      state.isReplaceUser = action.payload;
    },
    setUserReplace: (state, action) => {
      state.userReplace = action.payload;
    },
    showSuccessMessage: (state, action) => {
      state.showSuccessMessage = action.payload;
    },
    setTokenGenio: (state, action) => {
      state.tokenGenio = action.payload;
    },
  },
});

export const {
  signIn,
  signToken,
  signOut,
  changePass,
  setMenuUser,
  setMenuUserReplace,
  setMenuInitialUser,
  setIsReplaceUser,
  setUserReplace,
  showSuccessMessage,
  setTokenGenio
} = authSlice.actions;
export default authSlice.reducer;

export const getisLoggedIn = (state) => {
  return state.persistedReducer.isLoggedIn;
};
export const getisLoggedUserPassword = (state) => {
  return state.persistedReducer.isLoggedUserPass;
};

export const getisChangePass = (state) => {
  return state.persistedReducer.isChangePass;
};

export const getUser = (state) => {
  return state.persistedReducer.user;
};

export const getDataUser = (state) => {
  return state.persistedReducer.infoUser;
};

export const getMenuUser = (state) => {
  return state.persistedReducer.menuUser;
};

export const getInitialMenuUser = (state) => {
  return state.persistedReducer.initialMenuUser;
};

export const getIsReplaceUser = (state) => {
  return state.persistedReducer.isReplaceUser;
};

export const getUserReplace = (state) => {
  return state.persistedReducer.userReplace;
};

export const getTokenUser = (state) => {
  return state.persistedReducer.token;
};

export const getShowUserReplace = (state) => {
  return state.persistedReducer.showUserReplace;
};

export const getShowSuccessMessage = (state) => {
  return state.persistedReducer.showSuccessMessage;
};

export const getPasswordFormMessage = (state) => {
  return state.persistedReducer.passwordFormMessage;
};

export const getTokenGenio = (state) => {
  return state.persistedReducer.tokenGenio;
};
