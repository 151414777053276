import { apiSlice } from "../apiSlice";

export const forgotApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendEmail: builder.mutation({
      query: (parameter) => ({
        url: "/sendRestoreCode",
        method: "POST",
        body: {
          usridxxx: parameter.email,
        },
      }),
    }),
    loginRestoreCode: builder.mutation({
      query: (credentials) => ({
        url: "/loginRestoreCode",
        method: "POST",
        body: {
          usridxxx: credentials.usridxxx,
          usrtokrx: credentials.userCode,
        },
      }),
    }),
    restorePassword: builder.mutation({
      query: (credentials) => ({
        url: "/restorePassword",
        method: "POST",
        body: {
          usridxxx: credentials.usridxxx,
          usrpassx: credentials.password,
        },
      }),
    }),
    changePassword: builder.mutation({
      query: (credentials) => ({
        url: "/changePassword",
        method: "POST",
        body: {
          usridxxx: credentials.usridxxx,
          usrpassx_old: credentials.old_password,
          usrpassx: credentials.password,
        },
      }),
    }),
    SendTokenCode: builder.mutation({
      query: (parameter) => ({
        url: "/sendTokenCode",
        method: "POST",
        body: {
          usridxxx: parameter.email,
        },
      }),
    }),
  }),
});

export const { useSendEmailMutation } = forgotApiSlice;
export const { useLoginRestoreCodeMutation } = forgotApiSlice;
export const { useRestorePasswordMutation } = forgotApiSlice;
export const { useChangePasswordMutation } = forgotApiSlice;
export const { useSendTokenCodeMutation } = forgotApiSlice;


