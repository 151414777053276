import { Box, Button, Flexbox, Stack, Heading, Input, shootToast, StackRow } from "codekit";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Accept_Es_path from "../Images/Pieza-Aceptación-Cotización-Esp.jpg";
import Accept_In_path from "../Images/Pieza-Aceptación-Cotización-ing.jpg";
import Reject_Es_path from "../Images/Pieza-rechazo-cotizacion-esp.jpg";
import Reject_In_path from "../Images/Pieza-rechazo-cotizacion-ING.jpg";
import { useSaveDesistMutation } from "../libs/redux/slices/Ventasxx/Ventasxx/ventasxxApiSlice";

const LinkAcceptReject = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ mode: "onBlur" });
  const [SaveDesistimiento] = useSaveDesistMutation();
  const params = useParams();

  const [StatusCtzn, setStatusCtzn] = useState(params.status);
  const [Language, setLanguage] = useState(params.language);
  const [Tokenenv, setTokenenv] = useState(params.token);
  const [selectedRadio, setSelectedRadio] = useState("");

  const options = [
    {
      value: "Demora en el envío de propuesta comercial",
      label: { EN: "1. Delay in sending the comercial proposal", ES: "1. Demora en el envío de propuesta comercial." },
    },
    { value: "Servicio y alcance", label: { EN: "2. Service and scope", ES: "2. Servicio y alcance." } },
    {
      value: "No se sintió acompañado desde el área comercial.",
      label: {
        EN: "3. Did not feel supported by the commercial area.",
        ES: "3. No se sintió acompañado desde el área comercial.",
      },
    },
    {
      value: "Mejores condiciones en destino por otros operadores",
      label: {
        EN: "4. better conditions at destination by other operators",
        ES: "4. Mejores condiciones en destino por otros operadores.",
      },
    },
    { value: "Tarifas", label: { EN: "5. Rates", ES: "5. Tarifas." } },
    { value: "Investigación de mercado", label: { EN: "6. market research", ES: "6. Investigación de mercado." } },
  ];

  const backgroundStyle = () => {
    if (StatusCtzn === "ACEPTADA") {
      return Language === "ES" ? Accept_Es_path : Accept_In_path;
    }
    if (StatusCtzn === "RECHAZADA") {
      return Language === "ES" ? Reject_Es_path : Reject_In_path;
    }
    return null;
  };

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  const validSave = () => {
    if (!selectedRadio) {
      shootToast({
        variant: "error",
        text: "Seleccione una opción de envío",
        duration: 2000,
        autoHide: true,
      });
      return false;
    }
    return true;
  };

  const onSubmit = async (formData) => {
    console.log();
    if (validSave()) {
      const DataSave = {
        tokenenv: Tokenenv,
        qtclause: selectedRadio,
      };
      console.log(DataSave);
      try {
        await SaveDesistimiento(DataSave).unwrap();
      } catch (error) {
        console.error("Error durante la petición:", error);
      }
    }
  };

  return (
    <div
      className="flex items-center justify-center w-screen h-screen"
      style={{
        backgroundImage: `url(${backgroundStyle()})`,
        backgroundSize: StatusCtzn === "RECHAZADA" ? "70%" : "80%",
        backgroundPosition: "center",
        backgroundRepeat: "repeat-x",
        minHeight: "100vh",
        minWidth: "100vw",
      }}
    >
      {StatusCtzn === "RECHAZADA" && (
        <Box style={{ marginTop: "29%" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
              {options.map((option) => (
                <Stack key={option.value} style={{ display: "grid", gridTemplateColumns: "1fr 5fr" }}>
                  <Input name="Reject" value={option.value} type="radio" onChange={handleRadioChange} />
                  <Heading variant="heading-6" color="Marca-800">
                    {option.label[Language]}
                  </Heading>
                </Stack>
              ))}
            </Stack>
            <Box mt={4}>
              <StackRow forceWidth>
                <Flexbox justifyContent="center" alignItems="center" gap={4}>
                  <Button type="submit" variant="primary-bold" size="2l" minWidth={200}>
                    Enviar
                  </Button>
                </Flexbox>
              </StackRow>
            </Box>
          </form>
        </Box>
      )}
    </div>
  );
};

export default LinkAcceptReject;
