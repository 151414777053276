import { apiSlice } from "../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/loginCastor",
        method: "POST",
        body: {
          usridxxx: credentials.name,
          usrpassx: credentials.password,
          systemxx: "CASTOR",
        },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/logout",
        method: "GET",
      }),
    }),
  }),
});

export const authApiTokenSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginToken: builder.mutation({
      query: (credentials) => ({
        url: "/loginCastorCode",
        method: "POST",
        body: {
          usridxxx: credentials.usridxxx,
          usrtokxx: credentials.userCode,
          systemxx: "CASTOR",
        },
      }),
    }),
  }),
});

export const { useLoginMutation } = authApiSlice;
export const { useLogoutMutation } = authApiSlice;
export const { useLoginTokenMutation } = authApiTokenSlice;
