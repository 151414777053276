import { apiSlice } from "../apiSlice";

export const ganApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    SearchTerId: builder.mutation({
      query: (parameters) => ({
        url: `/Ganxxxxx/Ganxxxxx/${parameters.nit}`,
        method: "GET",
      }),
    }),
    CreateTer: builder.mutation({
      query: (parameters) => ({
        url: `/Ganxxxxx/Ganxxxxx`,
        method: "POST",
        body: parameters,
      }),
      invalidatesTags: ["Create"],
    }),
    SearchDocId: builder.mutation({
      query: (parameters) => ({
        url: `/Ganxxxxx/Document/${parameters.tipoAliadoc}`,
        method: "GET",
      }),
    }),
    SearchConTer: builder.mutation({
      query: (parameters) => ({
        url: `/Ganxxxxx/Contacts/${parameters.nit}`,
        method: "GET",
      }),
    }),
    CreateDocvsTer: builder.mutation({
      query: (parameters) => ({
        url: `Ganxxxxx/DocvsTer`,
        method: "POST",
        body: {
          teridxxx: parameters.teridxxx,
          iddocxxx: parameters.iddocxxx,
        },
      }),
    }),
    CreateMatriz: builder.mutation({
      query: (parameters) => ({
        url: `Ganxxxxx/Matrizxx`,
        method: "POST",
        body: {
          parameters,
        },
      }),
    }),
    SearchDocvsTer: builder.mutation({
      query: (parameters) => ({
        url: `Ganxxxxx/DocvsTer/${parameters.nit}`,
        method: "GET",
      }),
    }),
    linkDocu: builder.mutation({
      query: (parameters) => ({
        url: `Ganxxxxx/LinkDocu`,
        method: "POST",
        body: {
          tokenxxx: parameters,
        },
      }),
    }),
    CargDocs: builder.mutation({
      query: (parameters) => ({
        url: `Ganxxxxx/CargDocs`,
        method: "POST",
        body: parameters,
      }),
    }),
    DocTerIn: builder.mutation({
      query: (parameters) => ({
        url: `Ganxxxxx/DocTerIn/${parameters.nit}`,
        method: "GET",
      }),
    }),
    AproDocs: builder.mutation({
      query: (parameters) => ({
        url: `Ganxxxxx/AproDocs`,
        method: "POST",
        body: parameters,
      }),
    }),
    SearchTeridxx: builder.mutation({
      query: (parameters) => ({
        url: `Ganxxxxx/Ganxxxxx`,
        method: "GET",
        params: parameters,
      }),
    }),
    SearchTerAliSu: builder.mutation({
      query: (parameters) => ({
        url: `Ganxxxxx/TerAliSu`,
        method: "POST",
        body: parameters,
      }),
    }),  
    SearchCtznAcpt: builder.mutation({
      query: (parameters) => ({
        url: `/Ganxxxxx/Ganxxxxx`,
        method: "GET",
        params: parameters,
      }),
    }),
    CargaDocGenio: builder.mutation({
      query: (parameters) => ({
        url: `/Ganxxxxx/CargaDoc`,
        method: "POST",
        params: parameters,
      }),
    }),  
  }),
});

export const {
  useSearchTerIdMutation,
  useCreateTerMutation,
  useSearchDocIdMutation,
  useSearchConTerMutation,
  useCreateDocvsTerMutation,
  useCreateMatrizMutation,
  useSearchDocvsTerMutation,
  useAproDocsMutation,
  useLinkDocuMutation,
  useCargDocsMutation,
  useDocTerInMutation,
  useSearchTeridxxMutation,
  useSearchTerAliSuMutation,
  useSearchCtznAcptMutation,
  useCargaDocGenioMutation
} = ganApiSlice;
