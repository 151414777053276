/*
 * Componente contenedor de la pagina de inicio de sesion:
 * Agrega un contenedor a la pagina de inicio de sesion cuando debe cambiar contraseña o confirmar el codigo de seguridad
 */

import { Button, Spreen, SpreenBody, SpreenHeader, Stack, StackRow } from "codekit";

import Pwdrules from "../components/Pwdrules";
import Helpcard from "../components/Helpcard";
import Changpwd from "../components/Changpwd";


import { Link } from "react-router-dom";

const LoginPageChangePasswordWrapper = ({
  children,
  showPasswordRules = false,
  showLoginTicketCard = false,
  isSuccessPasswordRecovery = false,
}) => {
  return (
    <Spreen variant="change-password" srcBgImage="/images/change-pass.png">
      <SpreenBody variant="change-password" isSuccessPasswordRecovery={isSuccessPasswordRecovery}>
        <StackRow>
          <SpreenHeader variant="change-password" >
            <Link to={"/login"} state={{ clearCodeConfirmation: true }}>
              <a href="/login" className="link-inline">
              <Button variant="secondary" size="m" icon="back-arrow" asSpan />
              </a>
            </Link>
          </SpreenHeader>
          {children}
        </StackRow>
      </SpreenBody>
    </Spreen >
  );
};

export default LoginPageChangePasswordWrapper;
