import { createSlice } from "@reduxjs/toolkit";
import passwordStrengthValidator from "../../../../utils/password-strength-validator";

const passwordSlice = createSlice({
  name: "password",
  initialState: {
    passwordStrength: {
      hasLength: false,
      hasLowercase: false,
      hasUppercase: false,
      hasNumber: false,
      hasSpecialChar: false,
      isPasswordStrong: false,
    },
  },
  reducers: {
    setPasswordStrength: (state, action) => {
      const password = action.payload;
      state.passwordStrength = passwordStrengthValidator(password);
    },
    reset: (state) => {
      state.passwordStrength = {
        hasLength: false,
        hasLowercase: false,
        hasUppercase: false,
        hasNumber: false,
        hasSpecialChar: false,
        isPasswordStrong: false,
      };
    },
  },
});

export const { setPasswordStrength, reset } = passwordSlice.actions;
export default passwordSlice.reducer;

export const getPasswordStrength = (state) => {
  return state.password.passwordStrength;
};
