import { createSlice } from "@reduxjs/toolkit";
const auditoriSlice = createSlice({
  name: "auditori",
  initialState: {
    tableConcepts: [],
    valueInTable: [],
  },

  reducers: {
    updateDataTableConcepts: (state, action) => {
      state.tableConcepts = action.payload;
    },
    initValueinDataTableConcepts: (state, action) => {
      state.valueInTable = action.payload;
    },
    addValueinDataTableConcepts: (state, action) => {
      const { name } = action.payload;

      const newDate = [...state.valueInTable].map((item) => [
        ...[
          ...item,
          {
            [name]: "",
          },
        ],
      ]);

      state.valueInTable = [...newDate];
    },
    updateValueinDataTableConcepts: (state, action) => {
      console.log(state.valueInTable);
      const { indexRoot, indexItem, rowData, currentValue } = action.payload;

      const stateRaw = [...currentValue];

      const root = stateRaw[indexRoot];

      if (!root) return;

      const item = root[indexItem];

      if (!item) return;

      const newData = stateRaw.map((item, index) => {
        if (index !== indexRoot) return item;
        const newData = item.map((info, i) => {
          if (i !== indexItem) return info;
          return { ...rowData };
        });

        return newData;
      });

      state.valueInTable = [...newData];
    },
  },
});
export const {
  updateDataTableConcepts,
  updateValueinDataTableConcepts,
  initValueinDataTableConcepts,
  addValueinDataTableConcepts,
} = auditoriSlice.actions;
export default auditoriSlice.reducer;
