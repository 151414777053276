// Nuevo componente llamado Homenews.jsx
import React, { useEffect, useState } from 'react';
import { Box, Heading, StackRow, Stack, Cards } from 'codekit';
import { useSearchHomenewsMutation } from "../../libs/redux/slices/Homenews/homenewsApiSlice";


const Homenews = ({ origin }) => {
    const isLoginRequest = origin === "LOGIN-PAGE";
    const isForgotPasswordRequest = origin === "FORGOT-PAGE";

    // Busqueda general de noticias
    const [searchHomenews, { isLoading }] = useSearchHomenewsMutation();
    const [searchResults, setSearchResults] = useState([]);

    const search = async () => {
        try {
            const response = await searchHomenews().unwrap();
            setSearchResults(response);
        } catch (error) {
            // Manejo de errores
        }
    };
    

    // Hook que se utiliza para que cuando se le de clic se ejecute la funcion buscar todos los productos
    useEffect(() => {
        search(); // Llamada a la función search al cargar el módulo
    }, []);

    return (
        <Box spacing={6}>
            <Heading variant='heading-2'>Noticias</Heading>
            <StackRow>
                <Stack columns={3} gap={5}>
                    {searchResults.map((result, index) => (
                        <Cards
                            key={index}
                            imageUrl={`${result.notiimgx}`}
                        />
                    ))}
                </Stack>
            </StackRow>
        </Box>
    );
};

export default Homenews;
