/**
 * Componente de formulario de login: Permite iniciar sesion en la plataforma
 */

import {
  Body,
  Button,
  Description,
  Heading,
  Input,
  Label,
  Spreen,
  SpreenBody,
  SpreenFooter,
  SpreenLeft,
  SpreenRight,
  Stack,
  StackRow,
  Title,
} from "codekit";

import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { useLoginMutation } from "../../libs/redux/slices/authSlice/authApiSlice";
import { changePass, signIn, signToken, setTokenGenio } from "../../libs/redux/slices/authSlice/authSlice";
import { reset } from "../../libs/redux/slices/MenuSlice/menuSlice";
import { useLoginGenioMutation } from "../../libs/redux/slices/Genioxxx/genioxxxApiSlice";

const Lognform = () => {
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();
  const [loginGenio] = useLoginGenioMutation();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (formData) => {
    const { name } = formData;

    try {
      const userData = await login(formData).unwrap();
      const shouldValidate = userData?.data?.usridxxx;
      const hasTokenAccess = userData?.data[0]?.token;

      if (shouldValidate) {
        dispatch(signIn({ name, userData }));
      } else if (hasTokenAccess) {
        const { usridxxx } = userData?.data[0]?.infouser;
        dispatch(signToken({ usridxxx, userData }));
        dispatch(reset());
        await loginGenio({ USRIDXXX: usridxxx.toString() })
          .unwrap()
          .then((response) => {
            dispatch(setTokenGenio(response?.data[0]?.token));
          })
          .catch(() => {
            console.log("Erorr en la peticion de Token Genio");
          });
      }
    } catch (error) {
      const errorStatus = error.status;

      if (errorStatus === 422) {
        const errorChangePassword = error.data?.errors?.changePassword;

        if (errorChangePassword) {
          dispatch(changePass({ name: formData.name, error }));
        }
      }
    }
  };

  return (
    <Spreen variant="login" srcBgImage="/images/login-page.jpg">
      <SpreenLeft>
        <SpreenBody variant="login">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={8}>
              <StackRow>
                <Input
                  id="name"
                  type="text"
                  disabled={isLoading}
                  {...register("name", { required: true })}
                  isInvalid={errors.name}
                  placeholder="Usuario"
                  style={{ textTransform: "none" }}
                />
              </StackRow>
              <StackRow>
                <Input
                  id="password"
                  type="password"
                  disabled={isLoading}
                  {...register("password", { required: true })}
                  isInvalid={errors.password}
                  placeholder="Contraseña"
                  style={{ textTransform: "none" }}
                />
              </StackRow>
              <Button type="submit" variant="primary-bold" disabled={!isValid} isLoading={isLoading}>
                Ingresar
              </Button>
            </Stack>
          </form>
        </SpreenBody>
        <SpreenFooter>
          <Link to="/recover-password" className="link-inline">
            <Title asSpan variant="title-2" color="Negros-500">
              ¿Olvidaste tu contraseña?
            </Title>
          </Link>
        </SpreenFooter>
      </SpreenLeft>
    </Spreen>
  );
};

export default Lognform;
