import { apiSlice } from "../apiSlice";

export const homenewsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        searchHomenews: builder.mutation({
            query: (parameters) => ({
                url: `/Paramger/Homenews`,
                method: "GET",
            })
        }),
    }),
});

export const {useSearchHomenewsMutation } = homenewsApiSlice;
