import { apiSlice } from "../apiSlice";

export const dataTrackingSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDataTracking: builder.query({
      query: (parameters) => {
        if (!parameters.config) {
          return {
            url: `${parameters.modurlxx}/${parameters.produrlx}`,
            params: { ...parameters },
          };
        }
        const copy = Object.assign(parameters, {});
        const newObject = {};
        Object.entries(copy).forEach((entry) => {
          const [key, data] = entry;
          if (key === "config") return;
          newObject[key] = data;
        });
        let params = { ...newObject };
        if (parameters?.config?.params) {
          params = { ...params, ...parameters?.config?.params };
        }
        return {
          url: `${parameters.modurlxx}/${parameters.produrlx}${
            parameters?.config ? "/" + parameters?.config?.url : ""
          }`,
          params: { ...params },
        };
      },
      providesTags: ["Create"],
    }),
    getDataTrackingModal: builder.query({
      query: (parameters) => ({
        url: `${parameters.modurlxx}/${parameters.produrlx}`,
        params: { ...parameters },
      }),
      providesTags: ["Create"],
    }),
    hideColumns: builder.mutation({
      query: (parameters) => ({
        url: `/columns/${parameters.url.MODIDXXX}/${parameters.url.PROIDXXX}/${parameters.url.NOMCOLXX}`,
        method: "PUT",
        body: {
          ...parameters.body,
        },
      }),
    }),
    getHideColumnsUser: builder.mutation({
      query: (parameters) => ({
        url: `/columns/${parameters.modidxxx}/${parameters.proidxxx}`,
        method: "GET",
      }),
    }),
    getDataHistory: builder.mutation({
      query: (parameters) => ({
        url: `/Gestorlog/Serchlog`,
        method: "POST",
        body: { ...parameters },
      }),
    }),
  }),
});
export const { useGetDataTrackingQuery } = dataTrackingSlice;
export const { useGetDataTrackingModalQuery } = dataTrackingSlice;
export const { useHideColumnsMutation } = dataTrackingSlice;
export const { useGetHideColumnsUserMutation } = dataTrackingSlice;
export const { useGetDataHistoryMutation } = dataTrackingSlice;
